/* buttons */

.nm-b-icon {
	overflow: hidden;
}

.nm-b-icon::before {
	background-position: center center;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	position: relative;
}

/**
 * Configurator status code:
 * available,changeable,conflict,selected,relations
 * e.g.: nm-j-configurator-status_11000
 *
 */

.nm-b1 {
	background-color: #000;
	border: none;
	color: #fff;
	display: inline-block;
	padding: 10px 12px;
	text-decoration: none;
}

/* backbutton */

.nm-b2 {
	background: url("../assets/img/sprite-main.png") no-repeat 10px -64px #000;
	border: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	padding: 10px 12px 10px 25px;
	text-decoration: none;
}

.nm-b2:hover {
	background-color: #434c53;
}

/* morebutton */

.nm-b3 {
	background: no-repeat right center #000 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAICAYAAAAx8TU7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFRJREFUeNpi+v///6X/EMAAw0wMDAyTgfgyEP9ngAIWIJ4NxNxAnASVYAQJglRPgEqAwH8mBiwAJPgPiAuAOByIdUHaQYKpUPPAAjCVucgCIAAQYADC0BpjvpP2TQAAAABJRU5ErkJggg==");
	border-bottom: none;
	border-left: none;
	border-right: 12px solid #000;
	border-top: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	padding: 10px 13px 10px 12px;
	text-decoration: none;
}

.nm-layer-dialogue .nm-b3 {
	clear: both;
}

/* variation in grey with an arrow right */

.nm-b4 {
	background: url("../assets/img/sprite-main.png") right -15px no-repeat #e7e9ea;
	color: #000;
	display: block;
	padding: 10px 31px 10px 12px;
	text-decoration: none;
}

.nm-b4:hover {
	background-color: #d0d3d4;
}

/* module teaser button */

.nm-b5 {
	background: url("../assets/img/sprite-main.png") right -39px no-repeat var(--color-progressive-red);
	border: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	padding: 10px 35px 10px 12px;
	text-decoration: none;
}

/* add/remove to configuration button */

.nm-b6 {
	background: #000 no-repeat 5px 5px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGZJREFUeNrslMEKACAIQ1v0/7+8IOgSaWl2CNpR4TnSBZIpUjkF6wNNYiSQEjQfOsMpUIVpQHpgEnD2Pluw1hSiJ+UR3rPBZo3jcG0psDjrKos+rDdVghLCa1n2OMTEGd75vqoAAwDORRYjmSpWFQAAAABJRU5ErkJggg==");
	border: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	padding: 10px 12px 10px 30px;
	text-decoration: none;
}

.nm-b6:hover {
	background-color: #434c53;
}

/* available, changeable, no conflict/conflict, not selected */

.nm-j-configurator-status_10100 .nm-b6,
.nm-j-configurator-status_10101 .nm-b6,
.nm-j-configurator-status_11000 .nm-b6,
.nm-j-configurator-status_11001 .nm-b6,
.nm-j-configurator-status_11100 .nm-b6,
.nm-j-configurator-status_11101 .nm-b6 {
	background: #000 no-repeat 5px 5px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGZJREFUeNrslMEKACAIQ1v0/7+8IOgSaWl2CNpR4TnSBZIpUjkF6wNNYiSQEjQfOsMpUIVpQHpgEnD2Pluw1hSiJ+UR3rPBZo3jcG0psDjrKos+rDdVghLCa1n2OMTEGd75vqoAAwDORRYjmSpWFQAAAABJRU5ErkJggg==");
	color: #fff;
}

.nm-j-configurator-status_10000 .nm-b6 {
	display: none;
}

/* available, changeable, no conflict, selected */

.nm-j-configurator-status_10010 .nm-b6,
.nm-j-configurator-status_10011 .nm-b6,
.nm-j-configurator-status_11010 .nm-b6,
.nm-j-configurator-status_11011 .nm-b6 {
	background: no-repeat #f3f4f6 5px 5px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGFJREFUeNpi/P//PwM1ARMDlcEINJAFXeCiqAuxekGxyaj/eg9VXPgfjabIQGRDGCk1EK9h+Az8T45huAzEFj5EGYbLQEY0g4g2DJ+XGYkUIylhM5JqGFjhaGkzAgwECDAAckEVImF/zMQAAAAASUVORK5CYII=");
	color: #000;
}

/* available, selected, not changeable */

.nm-j-configurator-status_10010 .nm-b6,
.nm-j-configurator-status_10011 .nm-b6 {
	cursor: default;
}

.nm-j-configurator-item-loading .nm-b6,
.nm-j-configurator-item-loading .nm-b6:hover {
	background-color: #b0b6b8;
}

/* hides stacked button labels */

/* available, changeable, no conflict, not selected */

.nm-j-configurator-status_10100 .nm-b6-b,
.nm-j-configurator-status_10100 .nm-b6-c,
.nm-j-configurator-status_10101 .nm-b6-b,
.nm-j-configurator-status_10101 .nm-b6-c,
.nm-j-configurator-status_11000 .nm-b6-b,
.nm-j-configurator-status_11000 .nm-b6-c,
.nm-j-configurator-status_11001 .nm-b6-b,
.nm-j-configurator-status_11001 .nm-b6-c,
.nm-j-configurator-status_11100 .nm-b6-b,
.nm-j-configurator-status_11100 .nm-b6-c,
.nm-j-configurator-status_11101 .nm-b6-b,
.nm-j-configurator-status_11101 .nm-b6-c {
	display: none;
}

/* available, selected, not changeable */

.nm-j-configurator-status_10010 .nm-b6-a,
.nm-j-configurator-status_10010 .nm-b6-c,
.nm-j-configurator-status_10011 .nm-b6-a,
.nm-j-configurator-status_10011 .nm-b6-c {
	display: none;
}

/* hover */

/* available, changeable, no conflict, not selected */

.nm-j-configurator-status_11000 .nm-b6:hover,
.nm-j-configurator-status_11001 .nm-b6:hover {
	background-color: #434c53;
}

/* hides stacked button labels */

/* available, changeable, no conflict, selected */

.nm-j-configurator-status_11010 .nm-b6-a,
.nm-j-configurator-status_11010 .nm-b6-c,
.nm-j-configurator-status_11011 .nm-b6-a,
.nm-j-configurator-status_11011 .nm-b6-c {
	display: none;
}

/* hover */

/* available, changeable, no conflict, selected */

.nm-j-configurator-status_11010 .nm-b6:hover,
.nm-j-configurator-status_11011 .nm-b6:hover {
	background: no-repeat #f3f4f6 5px 5px url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHJJREFUeNpi/P//PwM1ARMDlcGogQhwltHkPz45ICbeQJhh2AxFEvtPtIHG/88wYnMpmgWM2PQy4kuHuLyNbCFJkYJNIz7D6J9sCEQKA0lhiKwR5k30SAGKk5Zs0MMMLfxITzYEIoX0ZDNa2gyMgQABBgBNCT8dY/REZAAAAABJRU5ErkJggg==");
	color: #000;
}

.nm-j-configurator-status_11010 .nm-b6:hover .nm-b6-c,
.nm-j-configurator-status_11011 .nm-b6:hover .nm-b6-c {
	display: block;
}

.nm-j-configurator-status_11010 .nm-b6:hover .nm-b6-b,
.nm-j-configurator-status_11011 .nm-b6:hover .nm-b6-b {
	display: none;
}

.nm-b1:hover,
.nm-b3:hover {
	background-color: #434c53;
}

.nm-b3:hover {
	border-right: 12px solid #434c53;
}

.nm-b5:hover {
	background-color: #aa142d;
}

/* status disabled */

.nm-b3.disabled {
	background-color: #b0b6b8;
	cursor: pointer;
}

/* Color and material options - a link and a button in selected status */

.nm-otp .nm-j-configurator-status_10010 .nm-a1,
.nm-otp .nm-j-configurator-status_10011 .nm-a1,
.nm-otp .nm-j-configurator-status_11010 .nm-a1,
.nm-otp .nm-j-configurator-status_11011 .nm-a1,
.nm-otp .nm-j-configurator-status_10000 .nm-b3,
.nm-otp .nm-j-configurator-status_10001 .nm-b3,
.nm-otp .nm-j-configurator-status_11000 .nm-b3,
.nm-otp .nm-j-configurator-status_11001 .nm-b3,
.nm-otp .nm-j-configurator-status_11100 .nm-b3,
.nm-otp .nm-j-configurator-status_11101 .nm-b3 {
	display: none;
}

/* checkboxes and radios */

.nm-checkbox-1,
.nm-checkbox-3 {
	/*	1 = square
		3 = round */
	background-color: #fff;
	border: 3px solid #000;
	cursor: pointer;
	display: inline-block;
	height: 24px;
	width: 24px;
}

.nm-checkbox-3 {
	border-radius: 24px;
}

.nm-engine-list .nm-checkbox-1,
.nm-engine-list .nm-checkbox-3 {
	left: 5px;
	position: absolute;
	top: 3px;
	width: 24px;
}

.nm-j-configurator-item-loading .nm-checkbox-1,
.nm-j-configurator-item-loading .nm-checkbox-3 {
	background: #fff;
	border: 3px solid #b0b6b8;
}

/* checkbox - selected (red) */

.nm-j-configurator-status_11010 .nm-checkbox-1 {
	background: var(--color-progressive-red) center center no-repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
	border: 3px solid var(--color-progressive-red);
}

/* checkbox - unselected - vermutlich redundant weil weiter oben schon definiert */

.nm-j-configurator-status_11000 .nm-checkbox-1,
.nm-j-configurator-status_11100 .nm-checkbox-1 {
	background-color: #fff;
	height: 24px;
	width: 24px;
}

/* checkbox - selected (black) */

.nm-j-configurator-status_10010 .nm-checkbox-1,
.nm-j-configurator-status_10011 .nm-checkbox-1 {
	background: #000 no-repeat center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
	border: 3px solid #000;
	cursor: default;
}

/* radio - selected (red) */

.nm-j-configurator-status_11010 .nm-checkbox-3 {
	background: var(--color-progressive-red) no-repeat center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
	border: 3px solid var(--color-progressive-red);
	border-radius: 30px;
	cursor: default;
}

/* radio - default - vermutlich redundant */

.nm-j-configurator-status_11000 .nm-checkbox-3,
.nm-j-configurator-status_11001 .nm-checkbox-3,
.nm-j-configurator-status_11100 .nm-checkbox-3 {
	border-radius: 24px;
}

/* radio - selected - black */

.nm-j-configurator-status_10010 .nm-checkbox-3,
.nm-j-configurator-status_10011 .nm-checkbox-3 {
	background: #000 no-repeat center center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJAQMAAAA8dl8XAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAYdEVYdENyZWF0aW9uIFRpbWUAMjkuMDMuMjAxMxbGg5cAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAAAI0lEQVQImWNgUGBgKGBgeMDAeIDBuYHhOQNDHQODDQODBAMAQu8ErolmuY0AAAAASUVORK5CYII=");
	border: 3px solid #000;
	cursor: default;
}

/* info icon */

.nm-info {
	background: top left no-repeat no-repeat transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RENGNDZCMjkyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RENGNDZCMkEyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0Y0NkIyNzI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0Y0NkIyODI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvP4m7EAAADLSURBVHjaYmDADjiBOAGI1wHxbSD+D8U3gHg1EEcBMRsDkSAWiB8iGYIL3wPiEHwGMQPxZCIMQsddUL0YYBoZhiEbigKiKDAMhuHeBwXuEyoYeBsWUQRdBwNEGBrFAiTCCUU7IyMjsSnEm4HIJEIsvgey+ie+RArxKdEu/cVERe/CDXzBQD3wFGTgBSoaeBZk4EoqGrgVVrJQNWFTK+uF07RwoKT4asdVfMFAOJG5BxRmgcTGFhu0OAIV9+eRDDkDxEuhBmF1FUCAAQCfWtTvGIyPEwAAAABJRU5ErkJggg==");
	display: block;
	height: 20px;
	position: relative;
	width: 20px;
}

.nm-info:hover {
	background: top left no-repeat no-repeat transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RENGNDZCMkQyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RENGNDZCMkUyNUNGMTFFMkJFQ0Q4QkFCMDI0QTAxQzIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0Y0NkIyQjI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0Y0NkIyQzI1Q0YxMUUyQkVDRDhCQUIwMjRBMDFDMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pgd7iYkAAAEiSURBVHjaYnT2CWbAAjiBOByI/YBYF4hVoOI3gfgyEK8H4jVA/AtdIxMWw2KB+AYQzwfiQCTDQEAdiEOAeClUTQg+A5mBeDIQLwJiOQbCQBGIVwNxF1QvGLAgKQAZlslAOiiF0mXILowi0zBkQ0NgBrJBnU0paAeZxQQ1WRqfyj2b14AxAQCKvBAWaPLAC1x8Q4h1pTfIhQYM1AOWIBdKEPIuCS6VZqKid0HgF8jAF1T08lOQgReoaOBZkIErqWjgVkZgaQMqWW4TSotEgDtArA1y4XdYPqQQ1MAiBQSWAfF0CgzrhgUdcrLJBeIpZBjWAcSV2MrDv1BDI4D4EZFhFgQ17C+28hAGVkKLeD9oPldByp5nodUAKPtsQjYIBgACDAD6UTikUcwgiAAAAABJRU5ErkJggg==");
}

.nm-engine-list .nm-info,
.nm-options-list .nm-info {
	float: right;
	position: absolute;
	right: 0;
	top: 3px;
}

/* energy efficiency button */

.nm-info-efficiency {
	background: #000;
	color: #fff;
	font: normal 11px/20px Verdana, sans-serif;
	padding: 1px 3px;
	text-decoration: none;
	text-transform: uppercase;
}

.nm-info-efficiency:hover {
	background-color: #434c53;
}

/* layer close button */

.nm-button-close {
	align-items: center;
	background-color: #000;
	box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, .15);
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-flex;
	height: 30px;
	justify-content: center;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 30px;
	z-index: 3;
}

.nm-button-close:hover {
	background-color: #434c53;
}

.nm-icon-close::before {
	height: 40px;
	width: 40px;
}

/* layer back button */

.nm-button-back {
	cursor: pointer;
	display: none;
}

/* Preview Mode ## NEMO-1328 */

.nm-btn-preview {
	background-image: none;
	left: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 224px;
	z-index: 10000;
}

.nm-btn-preview a {
	color: #fff;
	line-height: 2em;
}

.nm-btn-preview #preview_dropdown_select {
	background-color: #c00;
	color: #fff;
	font-family: Verdana, sans-serif;
	font-size: 10px;
	font-style: normal;
	font-variant: normal;
	font-weight: bold;
	height: 21px;
	padding: 2px 18px 2px 14px;
}

/* Mediacontrols */

/* Play button (Content, Stage, Teaser, Media) */

.nm-button-play a,
.nm-button-play button {
	background: #000;
	border: none;
	box-shadow: 0 0 0 10px rgba(255, 255, 255, .1);
	cursor: pointer;
	display: block;
	height: 70px;
	margin: 0 auto;
	opacity: 1;
	width: 70px;
}

/* Layer Buttons */

.nm-layer .nm-el-btn-03 {
	box-shadow: 0 0 0 5px rgba(255, 255, 255, .1);
	margin: 20px 47px;
	position: absolute;
	top: 5px;
	z-index: 2;
}

.nm-layer .nm-button-back {
	left: 0;
}

.nm-layer .nm-btn-continue {
	float: right;
}

.nm-layer .nm-btn-continue a {
	float: left;
}

/* playbutton responsive verhalten important, damit nicht irgendwelche anderen regeln dazwischenfunken */

@media all and (max-width: 1023px) {
	.nm-state-is-responsive .nm-button-play a,
	.nm-state-is-responsive .nm-button-play button {
		height: 50px !important;
		width: 50px !important;
	}
}

@media all and (max-width: 749px) {
	.nm-state-is-responsive .nm-button-play a,
	.nm-state-is-responsive .nm-button-play button {
		height: 35px !important;
		width: 35px !important;
	}

	.nm-state-is-responsive .nm-mediagallery-teaser .nm-button-play a,
	.nm-state-is-responsive .nm-mediagallery-teaser .nm-button-play button {
		margin-left: -17.5px !important;
		margin-top: -17.5px !important;
	}
}

.nm-button-play button:hover,
.nm-button-play a:hover {
	background: #434c53;
}

.nm-button-play button span,
.nm-button-play a span {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAbCAYAAABMU775AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEU3MkE1REIzMTE4MTFFNDg2RUZDMzUwMzA4MDdEQzQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEU3MkE1REMzMTE4MTFFNDg2RUZDMzUwMzA4MDdEQzQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0RTcyQTVEOTMxMTgxMUU0ODZFRkMzNTAzMDgwN0RDNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0RTcyQTVEQTMxMTgxMUU0ODZFRkMzNTAzMDgwN0RDNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjccRngAAACQSURBVHjavJRLDoAgDERb4sl0qxu9/1nwEzCAQGdY2IRASB4dKB31V4iIChlTmDN42Q4TdMnaMxk1SM32kKw1EIJboAn3wC5sgVKWKh7igAesH+zxkDjmdYekVmWz4AuPgA83Cv6bceiO+lsdP3/WUfIaDgBDUD+ybWUaV9NzWOuALTIFKV/V0skR6I5TgAEAjnfByVQpI6YAAAAASUVORK5CYII=");
	background-repeat: no-repeat;
	display: block;
	height: 27px;
	margin: 0 auto 0 40%;
	text-indent: -9999px;
	width: 14px;
}

@media all and (max-width: 1023px) {
	.nm-state-is-responsive .nm-button-play a span,
	.nm-state-is-responsive .nm-button-play button span {
		background-position: 0 center;
		background-size: 85%;
		margin: 0 auto 0 40%;
	}
}

@media all and (max-width: 749px) {
	.nm-state-is-responsive .nm-button-play a span,
	.nm-state-is-responsive .nm-button-play button span {
		background-position: center center;
		background-size: 75%;
		margin: 0 auto;
	}
}

.nm-button-play a span {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAdCAYAAACjbey/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDUwRDBDN0Q0RERBMTFFMjlBQzJDRDcyMTk2NDM3OTMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDUwRDBDN0U0RERBMTFFMjlBQzJDRDcyMTk2NDM3OTMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFOTQ0OEY3NDRERDgxMUUyOUFDMkNENzIxOTY0Mzc5MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NTBEMEM3QzREREExMUUyOUFDMkNENzIxOTY0Mzc5MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhKXrH0AAADvSURBVHjanNTBBgJRFAbg0wzDEEMMERERERHRC/Q0bVu1bdW2VatWrXqAIcoseoOeJNrfzsmJMbrTuf/Pv0j+T9PcmZZzLiWiIfdBQCJuwr1yxyggybkX/SUQIOlxS+4ABapIHwVIx6ViEEB6GSYkavhuqHeniwKSkSI5CpCeDy8SGf/siZ6TDgpIpopkKCCZ1ZEIOP5zbsFto4BkoUiKApJYnmQUuHOX3CcC3HT8+nziN1Lm7CnkDcalb0OAMzepjkOAEzeuj63A0Te2AAff0ALs/42bgJ1l7AO21vEvYBMyrgPr0HEVWCFj6VuAAQCBrmGCxtFIdgAAAABJRU5ErkJggg==") no-repeat center center;
	height: 100%;
}

.nm-video .nm-button-play-pause button.nm-video-button-pause {
	display: none;
}

.nm-video.nm-video-playing .nm-button-play-pause button.nm-video-button-pause {
	display: block;
}

.nm-video.nm-video-playing .nm-button-play-pause button.nm-video-button-play {
	display: none;
}

.nm-video.nm-video-playing .nm-button-overlay {
	display: block;
	opacity: 0;
}

.nm-md-collage .nm-video.nm-video-playing .nm-button-overlay {
	opacity: 0;
}

.nm-video .nm-video-button-play:focus {
	outline: 0;
}

.nm-md-collage .nm-video:not(.nm-video-playing) .nm-button-overlay {
	opacity: 1;
	transition-delay: 2s;
	transition-property: opacity;
}

.nm-su-submit {
	background: no-repeat center center #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA99pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ1dWlkOjVEMjA4OTI0OTNCRkRCMTE5MTRBODU5MEQzMTUwOEM4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjEyRDBBQUUzNzFFQjExRTI4MjUwQjg1MzRENkFDNTE4IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjEyRDBBQUUyNzFFQjExRTI4MjUwQjg1MzRENkFDNTE4IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIElsbHVzdHJhdG9yIENTNSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjNFMzhFQTU1MTIyMDY4MTE4QTZERUUyOTQ3MEMxNDIxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjNFMzhFQTU1MTIyMDY4MTE4QTZERUUyOTQ3MEMxNDIxIi8+IDxkYzp0aXRsZT4gPHJkZjpBbHQ+IDxyZGY6bGkgeG1sOmxhbmc9IngtZGVmYXVsdCI+RHJ1Y2s8L3JkZjpsaT4gPC9yZGY6QWx0PiA8L2RjOnRpdGxlPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtZ4nrgAAAE0SURBVHja7JbdDYIwFIWBB33tCG5g3QA3cARGYQNHcATiBLiBbqBOoK++WM8NR0OM0lus+kKTE1Jo7lfa+5c655J/jHQAD+DHwmyc45FzeoY27nrZ9SYLuEtJOsqhPeSgmtq25hOfjZd2PdCCgCVknr5NoAo6QTYamIbFaOHZ3EpOJCZYDNaKqzCaDYaAxdhCZaS5iioEnHX4nYEOSh89cr16ZJ7vWmMmNJq6wBKjVmlnyvWfxzHurGT8Gs/9WoZcHsu5DBNF/Q5OqDjhKnYCsYTvmUxMK8ZLQqs+2StRxmnZSpt3bbmZghsIggdVJxQK8V6L4rB5el/cHVJbOKKVRcArRsEM8PMvwXIaNadzHzxqIxACj96BaOFfaX3acIBnfXJ1v66m+cs5tB66zAH883ETYABGsO2ZS01QzAAAAABJRU5ErkJggg==");
	border: none;
	color: transparent;
	float: left;
	font: 0/0 serif;
	height: 30px;
	overflow: hidden;
	text-shadow: none;
	width: 30px;
}

.nm-icon-rhombus {
	color: transparent;
	font: 0/0 serif;
	overflow: hidden;
	text-shadow: none;
	white-space: nowrap;
}

.nm-icon-rhombus::before {
	background: left center no-repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAALCAYAAACZIGYHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODA5NzZDREIyRTZDMTFFM0JCOEZDQUZDNEE2N0ZCRTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODA5NzZDREMyRTZDMTFFM0JCOEZDQUZDNEE2N0ZCRTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4MDk3NkNEOTJFNkMxMUUzQkI4RkNBRkM0QTY3RkJFNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4MDk3NkNEQTJFNkMxMUUzQkI4RkNBRkM0QTY3RkJFNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkBagkUAAAEPSURBVHjadJKxSgNBEIZ37xRiIUIggmXEylLIGyRpzRtYWaeRkMdIL4iggo9hl9SxS6GlWFiLCXHyTbjJDptk4Gdmb2b+/Wf24tvRZfAWYzwTkT7hBfgLmZFfe2pquCkYHeRFJFu4W2qPRcKy6tSE5a1Ue7/APCc54aYbCut0au/huik1es5vwmfwW2QkHZqu040SdlnF+QAmGniSho4ByjR32Gcz8Go78iRt0DUVRmTynf3rGOSnVmM7OeV852+Wbflm79UuNks2JT3OV/4J98wyB0/g071SKGhCRRx6DZn8zUhAVTzmuQLGAb7pyneK4NsCdw9+8pzu5ByMbQ2y9U8oqZSEH5xe0rSpbiXAALzTZyS8Y/H6AAAAAElFTkSuQmCC");
	content: "";
	display: inline-block;
	height: 10px;
	padding-right: 17px;
}

.nm-footer .nm-icon-rhombus {
	display: none;
}

@media all and (max-width: 749px) {
	.nm-state-is-responsive .nm-el-btn.nm-el-btn-04 {
		padding-top: 21px;
	}
}

/* settings link */
.nm-icon-settings::before {
	background-position: top left;
	background-repeat: no-repeat;
	content: "";
	height: 32px;
	margin-left: -35px;
	position: absolute;
	top: 4px;
	width: 32px;
}

.nm-icon-expand-white,
.nm-icon-collapse-white {
	padding-right: 30px;
}

.nm-icon-collapse-white::after,
.nm-icon-expand-white::after {
	background-repeat: no-repeat;
	content: "";
	height: 22px;
	margin-top: -3px;
	position: absolute;
	right: 0;
	top: 50%;
	width: 22px;
}

.nm-icon-back-arrow::before,
.nm-icon-next-arrow::before {
	height: 40px;
	width: 40px;
}

.nm-icon-back-arrow-l::before,
.nm-icon-next-arrow-l::before {
	height: 50px;
	width: 50px;
}
