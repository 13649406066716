/*
	 BASIC ELEMENTS
	 >>> Copytext <<<
*/

/* Selector styles are for RTEditor - p-Tag is c3 Textblock */

.nm-text-block a,
.nm-text-block-grey a,
.nm-text-block-negative a,
.nm-text-large a,
.nm-text-footnote a,
.nm-text-list a,
.nm-text-list-bold a,
.nm-text-teaser a {
	text-decoration: underline !important; /* TODO: delete important when basic.generic is gone */
}

.nm-text-block a.nm-basic-link,
.nm-text-block-negative a.nm-basic-link,
.nm-text-block-grey a.nm-basic-link,
.nm-text-footnote a.nm-basic-link,
.nm-text-large a.nm-basic-link,
.nm-text-list a.nm-basic-link,
.nm-text-list-bold a.nm-basic-link,
.nm-text-teaser a.nm-basic-link {
	text-decoration: none;
}

.nm-text-large {
	font-family: AudiTypeWide, sans-serif;
	font-size: 2rem;
	font-weight: normal;
	line-height: 3.6rem;
}

/* NB: a is defined as text-link in basic.elements-links - following definition is for RTEditor */
.nm-text-large,
.nm-text-large a {
	color: #000;
}

@media all and (max-width: 749px) {
	.nm-text-large {
		font-size: 1.8rem;
		line-height: 3rem;
	}
}

.nm-text-teaser {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: var(--font-weight-light);
	line-height: 2.4rem;
}

.nm-text-teaser,
.nm-text-teaser a {
	color: #000;
}

.nm-text-block {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2.4rem;
}

.nm-text-block,
.nm-text-block a {
	color: #000;
}

.nm-text-block-bold {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: var(--font-weight-bold);
	line-height: 2.4rem;
}

.nm-text-block-bold,
.nm-text-block-bold a {
	color: #000;
}

.nm-text-block-negative {
	color: var(--color-progressive-red);
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2.4rem;
}

.nm-text-block-bold-negative {
	color: var(--color-progressive-red);
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: var(--font-weight-bold);
	line-height: 2.4rem;
}

.nm-text-block-grey {
	color: #808080;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2.4rem;
}

.nm-text-list {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
}

.nm-text-list,
.nm-text-list a {
	color: #000;
}

.nm-text-list-bold {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: var(--font-weight-bold);
	line-height: 2rem;
}

.nm-text-list-bold,
.nm-text-list-bold a {
	color: #000;
}

.nm-text-list-negative {
	color: var(--color-progressive-red);
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
}

.nm-text-list-grey {
	color: #808080;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
}

.nm-text-footnote {
	color: #808080;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.2rem;
	font-weight: normal;
	line-height: 1.6rem;
	margin: 0;
}

.nm-text-footnote a {
	color: #000;
}

.nm-text-block a:focus,
.nm-text-block a:hover {
	color: #4c4c4c;
}

.nm-text-block-bold a:focus,
.nm-text-block-bold a:hover {
	color: #4c4c4c;
}

.nm-text-footnote a:focus,
.nm-text-footnote a:hover {
	color: #4c4c4c;
}

.nm-text-large a:focus,
.nm-text-large a:hover {
	color: #4c4c4c;
}

.nm-text-list a:focus,
.nm-text-list a:hover {
	color: #4c4c4c;
}

.nm-text-list-bold a:focus,
.nm-text-list-bold a:hover {
	color: #4c4c4c;
}

.nm-text-teaser a:focus,
.nm-text-teaser a:hover {
	color: #4c4c4c;
}

[data-theme="dark"] .nm-text-block,
[data-theme="dark"] .nm-text-block a {
	color: #fff;
}

[data-theme="dark"] .nm-text-block-bold,
[data-theme="dark"] .nm-text-block-bold a {
	color: #fff;
}

[data-theme="dark"] .nm-text-block-grey {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-large,
[data-theme="dark"] .nm-text-large a {
	color: #fff;
}

[data-theme="dark"] .nm-text-list,
[data-theme="dark"] .nm-text-list a {
	color: #fff;
}

[data-theme="dark"] .nm-text-list-bold,
[data-theme="dark"] .nm-text-list-bold a {
	color: #fff;
}

[data-theme="dark"] .nm-text-list-grey {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-footnote {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-footnote a {
	color: #fff;
}

[data-theme="dark"] .nm-text-teaser,
[data-theme="dark"] .nm-text-teaser a {
	color: #fff;
}

[data-theme="dark"] .nm-text-large a:focus,
[data-theme="dark"] .nm-text-large a:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-teaser a:focus,
[data-theme="dark"] .nm-text-teaser a:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-block a:focus,
[data-theme="dark"] .nm-text-block a:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-block-bold a:focus,
[data-theme="dark"] .nm-text-block-bold a:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-list a:focus,
[data-theme="dark"] .nm-text-list a:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-list-bold a:focus,
[data-theme="dark"] .nm-text-list-bold a:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-text-footnote a:focus,
[data-theme="dark"] .nm-text-footnote a:hover {
	color: #b3b3b3;
}
