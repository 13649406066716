/*	=========================================================================
	Typography
	========================================================================= */

.nm-h1 {
	font: normal 36px/40px AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

.nm-h2 {
	clear: both;
	font: normal 18px/30px AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

/* Headline popup */

.nm-hl-pu {
	font: normal 26px/1.38 AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
	/* 36px line-height/font-size = 1.38 */
	margin-bottom: 10px;
}

.nm-h3,
.nm-h4 {
	font: normal 14px/20px AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

.nm-h5 {
	font: normal 12px/20px AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

.nm-h6 {
	font: normal 12px/20px Verdana, sans-serif;
}

.nm-layer-dialogue .nm-h6 {
	font-weight: 700;
}

.nm-form-item .nm-tooltip h4 {
	font: 700 11px/1 Verdana, sans-serif;
}

/*	bodycopy (line-height: 1 is the undefined default)
============================================================================= */

/* body copy */

.nm-c2,
.nm-c2-b,
.nm-c3 {
	font: normal 11px/20px Verdana, sans-serif;
}

/* i.e. descriptions, bold */

.nm-c2-b,
.nm-c3 {
	font-weight: bold;
}

/* i.e. intro text main teasers */

.nm-c1 {
	font: normal 18px/30px Verdana, sans-serif;
}

.nm-c3 {
	font: bold 11px/1 Verdana, sans-serif;
}

/* carline */

.nm-c6 {
	font: normal 32px/30px AudiTypeExtended, Verdana, sans-serif;
}

/* derivate definition */

.nm-c7 {
	font: normal 12px/1 AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

/* infotext bold */

.nm-c8 {
	font: bold 10px/15px Verdana, sans-serif;
}

/* infotext normal */

.nm-c9 {
	font: normal 10px/15px Verdana, sans-serif;
}

/* links and navigation */

.nm-a1,
.nm-a4 {
	font: bold 11px/1.5 Verdana, sans-serif;
}

/* navigation main */

.nm-a3,
.nm-a3 .nm-active {
	font: normal 12px/20px AudiTypeExtended, Verdana, sans-serif;
}

/* search results */

.nm-a3-b {
	font: normal 12px/1 AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
}

/* tabbuttons and buttons */

.nm-b1,
.nm-b2,
.nm-b3,
.nm-b4,
.nm-b5,
.nm-b6,
.nm-b7 {
	font: bold 10px/1 Verdana, sans-serif;
}

b {
	font-weight: 700;
}
