/*
	 BASIC ELEMENTS
	 >>> Headlines <<<
*/

/* Selector styles are for RTEditor */

.nm-headline {
	display: block;
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.8rem;
	font-weight: var(--font-weight-bold);
	line-height: 3rem;
	margin: 0 0 30px;
}

.nm-headline,
.nm-headline a {
	color: #000;
}

.nm-headline-normal {
	display: block;
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.8rem;
	font-weight: normal;
	line-height: 3rem;
	margin: 0 0 30px;
}

.nm-headline-normal,
.nm-headline-normal a {
	color: #000;
}

@media all and (max-width: 749px) {
	.nm-headline,
	.nm-headline-normal {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

.nm-headline-large {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 5rem;
	font-weight: var(--font-weight-bold);
	line-height: 6rem;
	margin: 0 0 30px;
}

.nm-headline-large,
.nm-headline-large a {
	color: #000;
}

@media all and (max-width: 767px) {
	.nm-headline-large {
		font-size: 3.6rem;
		line-height: 4rem;
	}
}

.nm-headline-medium {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 2.6rem;
	font-weight: var(--font-weight-bold);
	line-height: 3.6rem;
	margin: 0 0 30px;
}

/* TODO: Highlightmodule - should be placed there */
.nm-headline-medium + .nm-headline-normal {
	margin-bottom: 20px;
}

.nm-headline-medium,
.nm-headline-medium a {
	color: #000;
}

.nm-headline-medium-normal {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 2.6rem;
	font-weight: normal;
	line-height: 3.6rem;
	margin: 0 0 30px;
}

.nm-headline-medium-normal,
.nm-headline-medium-normal a {
	color: #000;
}

@media all and (max-width: 749px) {
	.nm-headline-medium,
	.nm-headline-medium-normal {
		font-size: 1.8rem;
		line-height: 2.4rem;
	}
}

.nm-headline-small {
	display: block;
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.3rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.8rem;
	margin: 0 0 20px;
}

.nm-headline-small,
.nm-headline-small a {
	color: #000;
}

.nm-headline-small-normal {
	display: block;
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.3rem;
	font-weight: normal;
	line-height: 1.8rem;
	margin: 0 0 20px;
}

.nm-headline-small-normal,
.nm-headline-small-normal a {
	color: #000;
}

.nm-headline-teaser {
	display: block;
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.5rem;
	font-weight: var(--font-weight-bold);
	line-height: 2rem;
	margin: 0 0 20px;
}

.nm-headline-teaser,
.nm-headline-teaser a {
	color: #000;
}

.nm-headline-teaser-normal {
	display: block;
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.5rem;
	font-weight: normal;
	line-height: 2rem;
	margin: 0 0 20px;
}

.nm-headline-teaser-normal,
.nm-headline-teaser-normal a {
	color: #000;
}

@media all and (max-width: 749px) {
	.nm-headline-teaser,
	.nm-headline-teaser-normal {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}
}

.nm-headline-wide {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 3.6rem;
	font-weight: var(--font-weight-bold);
	line-height: 4rem;
	margin: 0 0 30px;
}

.nm-headline-wide,
.nm-headline-wide a {
	color: #000;
}

@media all and (max-width: 749px) {
	.nm-headline-wide {
		font-size: 2.6rem;
		line-height: 3.6rem;
	}
}

.nm-headline a:focus,
.nm-headline a:hover {
	color: #4c4c4c;
}

.nm-headline-large a:focus,
.nm-headline-large a:hover {
	color: #4c4c4c;
}

.nm-headline-medium a:hover,
.nm-headline-medium a:focus {
	color: #4c4c4c;
}

.nm-headline-medium-normal a:hover,
.nm-headline-medium-normal a:focus {
	color: #4c4c4c;
}

.nm-headline-normal a:hover,
.nm-headline-normal a:focus {
	color: #4c4c4c;
}

.nm-headline-small a:focus,
.nm-headline-small a:hover {
	color: #4c4c4c;
}

.nm-headline-small-normal a:focus,
.nm-headline-small-normal a:hover {
	color: #4c4c4c;
}

.nm-headline-wide a:hover,
.nm-headline-wide a:focus {
	color: #4c4c4c;
}

[data-theme="dark"] .nm-headline,
[data-theme="dark"] .nm-headline a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-large,
[data-theme="dark"] .nm-headline-large a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-medium,
[data-theme="dark"] .nm-headline-medium a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-medium-normal,
[data-theme="dark"] .nm-headline-medium-normal a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-normal,
[data-theme="dark"] .nm-headline-normal a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-small,
[data-theme="dark"] .nm-headline-small a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-small-normal,
[data-theme="dark"] .nm-headline-small-normal a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-teaser,
[data-theme="dark"] .nm-headline-teaser a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-teaser-normal,
[data-theme="dark"] .nm-headline-teaser-normal a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-wide,
[data-theme="dark"] .nm-headline-wide a {
	color: #fff;
}

[data-theme="dark"] .nm-headline-large a:focus,
[data-theme="dark"] .nm-headline-large a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-wide a:focus,
[data-theme="dark"] .nm-headline-wide a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-medium a:focus,
[data-theme="dark"] .nm-headline-medium a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-medium-normal a:focus,
[data-theme="dark"] .nm-headline-medium-normal a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline a:focus,
[data-theme="dark"] .nm-headline a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-normal a:focus,
[data-theme="dark"] .nm-headline-normal a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-small a:focus,
[data-theme="dark"] .nm-headline-small a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-small-normal a:focus,
[data-theme="dark"] .nm-headline-small-normal a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-teaser a:focus,
[data-theme="dark"] .nm-headline-teaser a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-headline-teaser-normal a:focus,
[data-theme="dark"] .nm-headline-teaser-normal a:hover {
	color: #d0d3d4;
}
