/*
	 BASIC ELEMENTS
	 >>> Links <<<
*/

.nm-link-basic,
.nm-link-back,
.nm-link-cta,
.nm-link-footnote,
.nm-link-navigation-1,
.nm-link-navigation-2,
.nm-link-text {
	cursor: pointer;
	display: inline-block;
}

.nm-link-basic[data-state="inactive"],
.nm-link-back[data-state="inactive"],
.nm-link-cta[data-state="inactive"],
.nm-link-footnote[data-state="inactive"],
.nm-link-navigation-1[data-state="inactive"],
.nm-link-navigation-2[data-state="inactive"],
.nm-link-text[data-state="inactive"] {
	pointer-events: none;
}

.nm-link-basic[data-state="active"],
.nm-link-back[data-state="active"],
.nm-link-cta[data-state="active"],
.nm-link-footnote[data-state="active"],
.nm-link-navigation-1[data-state="active"],
.nm-link-navigation-2[data-state="active"],
.nm-link-text[data-state="active"] {
	pointer-events: none;
}

.nm-icon-arrow {
	display: inline-block;
	fill: currentColor;
	height: 1.6rem;
	stroke: currentColor;
	stroke-width: 0;
	width: 1.6rem;
}

.nm-link-basic {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
}

.nm-link-basic,
.nm-link-basic:active,
.nm-link-basic[data-state="active"] {
	color: #000;
}

.nm-link-basic:hover,
.nm-link-basic:focus {
	color: #4c4c4c;
}

.nm-link-basic[data-state="inactive"] {
	color: #b3b3b3;
}

.nm-link-basic .nm-icon-arrow {
	vertical-align: middle;
}

.nm-link-basic .nm-brand-plus {
	color: var(--color-progressive-red);
}

[data-theme="dark"] .nm-link-basic,
[data-theme="dark"] .nm-link-basic:active,
[data-theme="dark"] .nm-link-basic[data-state="active"] {
	color: #fff;
}

[data-theme="dark"] .nm-link-basic:focus,
[data-theme="dark"] .nm-link-basic:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-basic[data-state="inactive"] {
	color: #4c4c4c;
}

.nm-link-back {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
}

.nm-link-back,
.nm-link-back:active,
.nm-link-back[data-state="active"] {
	color: #000;
}

.nm-link-back:hover,
.nm-link-back:focus {
	color: #4c4c4c;
}

.nm-link-back[data-state="inactive"] {
	color: #b3b3b3;
}

.nm-link-back .nm-icon-arrow {
	margin-right: 10px;
	transform: rotate(180deg);
	vertical-align: text-bottom;
}

[data-theme="dark"] .nm-link-back,
[data-theme="dark"] .nm-link-back:active,
[data-theme="dark"] .nm-link-back[data-state="active"] {
	color: #fff;
}

[data-theme="dark"] .nm-link-back:focus,
[data-theme="dark"] .nm-link-back:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-back[data-state="inactive"] {
	color: #4c4c4c;
}

.nm-link-cta {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
}

.nm-link-cta,
.nm-link-cta:active,
.nm-link-cta[data-state="active"] {
	color: #000;
}

.nm-link-cta:focus,
.nm-link-cta:hover {
	color: #4c4c4c;
}

.nm-link-cta[data-state="inactive"] {
	color: #b3b3b3;
}

.nm-link-cta .nm-icon-arrow {
	vertical-align: text-bottom;
}

[data-theme="dark"] .nm-link-cta,
[data-theme="dark"] .nm-link-cta:active,
[data-theme="dark"] .nm-link-cta[data-state="active"] {
	color: #fff;
}

[data-theme="dark"] .nm-link-cta:focus,
[data-theme="dark"] .nm-link-cta:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-cta[data-state="inactive"] {
	color: #4c4c4c;
}

.nm-link-footnote {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.2rem;
	font-weight: normal;
}

.nm-link-footnote sup {
	font-size: 1.2rem;
	left: 2px; /* 3px span plus 2px = 5px */
	position: relative;
	top: -5px;
}

/* footnote linktext color stays - only sup changes color - also for inactive states */
.nm-link-footnote span,
.nm-link-footnote[data-state="inactive"] span,
.nm-link-footnote span a,
.nm-link-footnote sup,
.nm-link-footnote:active sup {
	color: #000;
}

.nm-link-footnote span a {
	text-decoration: underline;
}

.nm-link-footnote:focus sup,
.nm-link-footnote:hover sup,
.nm-link-footnote span a:hover {
	color: #4c4c4c;
}

.nm-link-footnote[data-state="inactive"] sup {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-footnote span,
[data-theme="dark"] .nm-link-footnote[data-state="inactive"] span,
[data-theme="dark"] .nm-link-footnote span a,
[data-theme="dark"] .nm-link-footnote sup,
[data-theme="dark"] .nm-link-footnote:active sup {
	color: #fff;
}

[data-theme="dark"] .nm-link-footnote:focus sup,
[data-theme="dark"] .nm-link-footnote:hover sup,
[data-theme="dark"] .nm-link-footnote a:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-footnote[data-state="inactive"] sup {
	color: #4c4c4c;
}

.nm-link-navigation-1 {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
}

.nm-link-navigation-1,
.nm-link-navigation-1:active,
.nm-link-navigation-1[data-state="active"] {
	color: #000;
}

.nm-link-navigation-1[data-state="active"] {
	border-bottom: 1px solid;
}

.nm-link-navigation-1:focus,
.nm-link-navigation-1:hover {
	color: #4c4c4c;
}

.nm-link-navigation-1[data-state="inactive"] {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-navigation-1,
[data-theme="dark"] .nm-link-navigation-1:active,
[data-theme="dark"] .nm-link-navigation-1[data-state="active"] {
	color: #fff;
}

[data-theme="dark"] .nm-link-navigation-1[data-state="active"] {
	border-bottom: 1px solid;
}

[data-theme="dark"] .nm-link-navigation-1:focus,
[data-theme="dark"] .nm-link-navigation-1:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-navigation-1[data-state="inactive"] {
	color: #4c4c4c;
}

.nm-link-navigation-2 {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 1.5rem;
}

.nm-link-navigation-2,
.nm-link-navigation-2:active,
.nm-link-navigation-2[data-state="active"] {
	color: #4c4c4c;
}

.nm-link-navigation-2:hover,
.nm-link-navigation-2:focus {
	color: #000;
}

.nm-link-navigation-2[data-state="inactive"] {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-navigation-2,
[data-theme="dark"] .nm-link-navigation-2:active,
[data-theme="dark"] .nm-link-navigation-2[data-state="active"] {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-navigation-2:focus,
[data-theme="dark"] .nm-link-navigation-2:hover {
	color: #fff;
}

[data-theme="dark"] .nm-link-navigation-2-inverted[data-state="inactive"] {
	color: #666;
}

.nm-link-text {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
	text-decoration: underline;
}

.nm-link-text,
.nm-link-text:active,
.nm-link-text[data-state="active"] {
	color: #000;
}

.nm-link-text:focus,
.nm-link-text:hover {
	color: #4c4c4c;
}

.nm-link-text[data-state="inactive"] {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-text,
[data-theme="dark"] .nm-link-text:active,
[data-theme="dark"] .nm-link-text[data-state="active"] {
	color: #fff;
}

[data-theme="dark"] .nm-link-text:focus,
[data-theme="dark"] .nm-link-text:hover {
	color: #b3b3b3;
}

[data-theme="dark"] .nm-link-text[data-state="inactive"] {
	color: #4c4c4c;
}
