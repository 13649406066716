/*******************************************************************

    basic typography
	mobile-first
	note: 	always use html.nm-scope-far-east
			Verdana, "Microsoft Yahei", SimSun, "Heiti SC", "Droid sans"

*******************************************************************/

html.nm-scope-far-east body h1,
html.nm-scope-far-east body h2,
html.nm-scope-far-east body h3,
html.nm-scope-far-east body h4,
html.nm-scope-far-east body h5,
html.nm-scope-far-east body h6,
html.nm-scope-far-east body strong,
html.nm-scope-far-east body .gsa-search-result h2,
html.nm-scope-far-east body .nm-dealermap .nm-map-data-index,
html.nm-scope-far-east body .gsa-search-result .content-header h1,
html.nm-scope-far-east body .gsa-search-result .search-results-count,
html.nm-scope-far-east body .gsa-search-result .search-results-title,
html.nm-scope-far-east body .nm-layer .nm-navigation-derivative-sub-list a {
	font-weight: bold;
}

html.nm-scope-far-east body {
	font-family: Verdana, "Microsoft Yahei", SimSun, "Heiti SC", "Droid sans", sans-serif;
}

html.nm-scope-far-east body span.nm-directlink {
	font-weight: normal;
}

html.nm-scope-far-east .nm-headline-medium {
	font-size: 2.3rem;
	line-height: 2.8rem;
}

html.nm-scope-far-east .nm-headline-teaser {
	font-size: 1.4rem;
	line-height: 2.2rem;
}

html.nm-scope-far-east .nm-text-block {
	font-size: 1.4rem;
}

html.nm-scope-far-east .nm-text-list {
	font-size: 1.4rem;
	line-height: 2rem;
}

.nm-form-standard-error::before,
.nm-error::before {
	top: 2px;
}

html.nm-scope-far-east .nm-md-highlight .nm-headline-medium {
	font-size: 3.2rem;
	line-height: 4rem;
}

html.nm-scope-far-east .nm-md-highlight .nm-headline-teaser {
	font-size: 1.4rem;
	line-height: 2.2rem;
}

html.nm-scope-far-east .nm-md-intro .nm-text-block {
	font-size: 1.6rem;
	line-height: 2.4rem;
}

html.nm-scope-far-east .nm-md-intro .nm-link-basic {
	font-size: 1.2rem;
	line-height: 1.8rem;
}

@media all and (max-width: 749px) {
	html.nm-scope-far-east.nm-state-is-responsive .nm-headline-medium {
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
}
