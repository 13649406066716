/*
	 BASIC ELEMENTS
	 >>> Buttons <<<
*/

.nm-button-2d,
.nm-button-360,
.nm-button-gallery,
.nm-button-ghost,
.nm-button-option,
.nm-button-red,
.nm-button-standard {
	align-items: center;
	border: 1px solid transparent; /* button jumping for hover states with border */
	box-sizing: border-box;
	cursor: pointer;
	display: inline-flex;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 2rem;
	overflow: hidden;
	position: relative;
}

.nm-button-ghost,
.nm-button-option,
.nm-button-red,
.nm-button-standard {
	height: 50px;
	max-width: 290px;
	min-width: 60px;
	padding: 0 30px;
}

.nm-button-ghost span,
.nm-button-option span,
.nm-button-red span,
.nm-button-standard span {
	display: block;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
}

.nm-button-gallery[data-state="inactive"],
.nm-button-ghost[data-state="inactive"],
.nm-button-option[data-state="inactive"],
.nm-button-red[data-state="inactive"],
.nm-button-standard[data-state="inactive"] {
	pointer-events: none;
}

.nm-icon-2d,
.nm-icon-360,
.nm-icon-back,
.nm-icon-camera,
.nm-icon-cancel,
.nm-icon-forward,
.nm-icon-select,
.nm-icon-setting {
	display: inline-block;
	fill: currentColor;
	height: 3.2rem;
	stroke: currentColor;
	stroke-width: 0;
	width: 3.2rem;
}

.nm-button-standard,
.nm-button-standard a,
.nm-button-standard:active,
.nm-button-standard[data-state="active"] {
	background-color: #000;
	color: #fff;
}

.nm-button-standard:focus,
.nm-button-standard:hover,
.nm-button-standard:hover a {
	background-color: #4c4c4c;
}

.nm-button-standard[data-state="inactive"] {
	background-color: #b3b3b3;
}

[data-theme="dark"] .nm-button-standard,
[data-theme="dark"] .nm-button-standard:active,
[data-theme="dark"] .nm-button-standard[data-state="active"] {
	background-color: #fff;
	color: #000;
}

[data-theme="dark"] .nm-button-standard:hover,
[data-theme="dark"] .nm-button-standard:focus {
	background-color: #b3b3b3;
}

[data-theme="dark"] .nm-button-standard[data-state="inactive"] {
	background-color: #4c4c4c;
}

.nm-button-ghost,
.nm-button-ghost:active,
.nm-button-ghost[data-state="active"] {
	border-color: #000;
	color: #000;
}

.nm-button-ghost:focus,
.nm-button-ghost:hover {
	border-color: #4c4c4c;
	color: #4c4c4c;
}

.nm-button-ghost[data-state="inactive"] {
	border-color: #b3b3b3;
	color: #b3b3b3;
}

[data-theme="dark"] .nm-button-ghost,
[data-theme="dark"] .nm-button-ghost:active,
[data-theme="dark"] .nm-button-ghost[data-state="active"] {
	border-color: #fff;
	color: #fff;
}

[data-theme="dark"] .nm-button-ghost:focus,
[data-theme="dark"] .nm-button-ghost:hover {
	border-color: #b3b3b3;
	color: #b3b3b3;
}

[data-theme="dark"] .nm-button-ghost[data-state="inactive"] {
	border-color: #4c4c4c;
	color: #4c4c4c;
}

.nm-button-red,
.nm-button-red:active,
.nm-button-red[data-state="active"] {
	background-color: var(--color-progressive-red);
	color: #fff;
}

.nm-button-red:focus,
.nm-button-red:hover {
	background-color: #c12244;
}

.nm-button-red[data-state="inactive"] {
	background-color: #b3b3b3;
}

/* - Button Option - uses a label instead of span for toggeling submenu - */
.nm-button-option-wrapper {
	position: relative;
}

.nm-button-option,
.nm-button-option:active,
.nm-button-option[data-state="active"] {
	background-color: #000;
	border-color: #b3b3b3;
	color: #fff;
}

.nm-button-option:focus,
.nm-button-option:hover {
	background-color: #4c4c4c;
	border-color: #b3b3b3;
}

.nm-button-option[data-state="inactive"] {
	background-color: #b3b3b3;
	border-color: #b3b3b3;
}

.nm-button-option .nm-icon-setting {
	margin-right: 10px;
}

/* toggle label */
.nm-button-option label {
	align-items: center;
	cursor: pointer;
	display: flex;
}

.nm-button-option-menu {
	background: #fff;
	bottom: 100%;
	display: none;
	position: absolute;
	width: 20em;
}

/* hidden checkbox */
input[type="checkbox"]#nm-button-option-toggle {
	display: none;
	position: absolute;
}

/* toggles the menu */
#nm-button-option-toggle ~ .nm-button-option-menu {
	display: none;
}

#nm-button-option-toggle:checked ~ .nm-button-option-menu {
	display: block;
}

/* cancel button in menu - only visible in smaller viewports */
.nm-button-option-menu .nm-icon-cancel {
	cursor: pointer;
	display: none;
}

.nm-button-option-menu ul {
	margin: 0 10px;
}

.nm-button-option-menu .nm-link-navigation-2 {
	border-bottom: 1px solid #b3b3b3;
	display: block;
}

.nm-button-option-menu .nm-link-navigation-2:last-of-type {
	border-bottom: none;
}

/* label link-navigation for toggling menu */
.nm-button-option-menu .nm-link-navigation-2 label {
	cursor: pointer;
	display: block;
	padding: 20px 0;
}

@media all and (max-width: 599px) {
	.nm-button-option {
		max-width: 50px;
		min-width: 50px;
		padding: 8px;
	}

	/* hide text */
	.nm-button-option > span,
	.nm-button-option > label span {
		display: none;
	}

	/* no text, no margin */
	.nm-button-option .nm-icon-setting {
		margin-right: 0;
	}

	/* spreads the menu over the whole viewport */
	.nm-button-option-menu {
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 2;
	}

	.nm-button-option-menu .nm-icon-cancel {
		display: block;
		float: right;
		height: 4.8rem;
		margin: 20px;
		width: 4.8rem;
	}

	.nm-button-option-menu ul {
		margin: 40px 20px 0;
	}
}

/* - Button Gallery (Camera Icon) - */
.nm-button-gallery {
	background-color: #000;
	border-color: #b3b3b3;
	color: #fff;
	height: 40px;
	padding: 0 3px; /* icon 32px + border 2px + padding centers the icon */
}

.nm-button-gallery:focus,
.nm-button-gallery:hover {
	background-color: #4c4c4c;
}

.nm-button-gallery span {
	display: none;
}

.nm-button-360 {
	background-color: rgba(0, 0, 0, .3);
	border-color: rgba(255, 255, 255, .1);
	border-radius: 50%;
	color: #fff;
	height: 50px;
	padding: 4px; /* centers the svg for ie */
	width: 50px;
}

.nm-button-360:focus,
.nm-button-360:hover {
	background-color: rgba(0, 0, 0, .4);
	border-color: rgba(255, 255, 255, .2);
}

.nm-button-360 span {
	display: none;
}

.nm-icon-360 {
	height: 4rem;
	width: 4rem;
}

.nm-button-2d {
	background-color: rgba(0, 0, 0, .3);
	border-color: rgba(255, 255, 255, .1);
	border-radius: 50%;
	color: #fff;
	height: 50px;
	width: 50px;
}

.nm-button-2d:focus,
.nm-button-2d:hover {
	background-color: rgba(0, 0, 0, .4);
	border-color: rgba(255, 255, 255, .2);
}

.nm-button-2d span {
	display: none;
}

.nm-icon-2d {
	height: 4em;
	width: 4em;
}

/* form submits */
.nm-icon-arrow-submit {
	display: inline-block;
	fill: #fff;
	height: 1.6rem;
	stroke-width: 0;
	width: 1.6rem;
}
