/*
	 BASIC ELEMENTS
	 >>> Label <<<
*/

/* Links are not explicitly in most defined therefore they will look like the default style so far */

.nm-label-info {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 1.8rem;
	margin: 0 0 20px;
}

.nm-label-info,
.nm-label-info a {
	color: #000;
}

.nm-label-info-bold {
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.4rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.8rem;
	margin: 0 0 20px;
}

.nm-label-info-bold,
.nm-label-info-bold a {
	color: #000;
}

.nm-label-text {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 1.8rem;
	margin: 0 0 20px;
}

.nm-label-text,
.nm-label-text a {
	color: #000;
}

.nm-label-text-bold {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 1.4rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.8rem;
	margin: 0 0 20px;
}

.nm-label-text-bold,
.nm-label-text-bold a {
	color: #000;
}

.nm-label-text-wide {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 3.6rem;
	font-weight: normal;
	line-height: 4rem;
	margin: 0 0 20px;
}

.nm-label-text-wide,
.nm-label-text-wide a {
	color: #000;
}

.nm-label-text-wide a:focus,
.nm-label-text-wide a:hover {
	color: #4c4c4c;
}

.nm-label-text-wide[data-state="inactive"] {
	color: #6d7579;
	pointer-events: none;
}

[data-theme="dark"] .nm-label-info,
[data-theme="dark"] .nm-label-info a {
	color: #fff;
}

[data-theme="dark"] .nm-label-info-bold,
[data-theme="dark"] .nm-label-info-bold a {
	color: #fff;
}

[data-theme="dark"] .nm-label-text,
[data-theme="dark"] .nm-label-text a {
	color: #fff;
}

[data-theme="dark"] .nm-label-text-bold,
[data-theme="dark"] .nm-label-text-bold a {
	color: #fff;
}

@media all and (max-width: 749px) {
	.nm-label-text-wide {
		font-size: 2.6rem;
		line-height: 3rem;
	}
}

[data-theme="dark"] .nm-label-text-wide,
[data-theme="dark"] .nm-label-text-wide a {
	color: #fff;
}

[data-theme="dark"] .nm-label-text-wide a:focus,
[data-theme="dark"] .nm-label-text-wide a:hover {
	color: #d0d3d4;
}

[data-theme="dark"] .nm-label-text-wide[data-state="inactive"] {
	color: #b0b6b8;
	pointer-events: none;
}
