/*  ------------------------------------------------------
	new Page Template when page-type"widescreen" is set
	------------------------------------------------------ */

[page-override="widescreen"],
[page-type="widescreen"] {
	background-color: #fff;
	margin: 0;
}

[page-override="widescreen"] .nm-wrapper,
[page-type="widescreen"] .nm-wrapper {
	background-color: #fff;
	max-width: 1920px;
	min-height: 70vh;
	min-width: 0;
	overflow: initial;
}

[page-override="widescreen"] .nm-content,
[page-type="widescreen"] .nm-content,
.nm-state-is-responsive [page-type="widescreen"] .nm-content {
	max-width: 100%;
	min-height: 0;
	padding-bottom: 1px;
	padding-top: 0;
	width: 100%;
}

[page-override="widescreen"] .nm-nav-wrap,
[page-type="widescreen"] .nm-nav-wrap {
	background-color: #000;
	max-width: 1920px;
}
