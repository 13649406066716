/*******************************************************************

    basic typography legacy

*******************************************************************/

html.nm-scope-far-east body .nm-h1,
html.nm-scope-far-east body .nm-h2,
html.nm-scope-far-east body .nm-h3,
html.nm-scope-far-east body .nm-h4,
html.nm-scope-far-east body .nm-h5,
html.nm-scope-far-east body .nm-h6,
html.nm-scope-far-east body .nm-a3-b,
html.nm-scope-far-east body .nm-a5,
html.nm-scope-far-east body .nm-c7,
html.nm-scope-far-east body .nm-el-lbl-03,
html.nm-scope-far-east body .nm-el-lk-05,
html.nm-scope-far-east body .nm-el-lk-06,
html.nm-scope-far-east body .nm-el-lk-07,
html.nm-scope-far-east body .nm-el-lk-08,
html.nm-scope-far-east body .nm-el-lk-09,
html.nm-scope-far-east body .nm-link-cta,
html.nm-scope-far-east body .nm-at-hdl-b,
html.nm-scope-far-east body .nm-hl-pu,
html.nm-scope-far-east body span.nm-el-hdl,
html.nm-scope-far-east body .nm-homepage-edit-mode .nm-h2,
html.nm-scope-far-east body .nm-homepage-edit-mode .nm-hl-pu,
html.nm-scope-far-east body .nm-simpleform .nm-2col .nm-hl-pu,
html.nm-scope-far-east body .nm-homepage .nm-homepage-bottom-wide-news-teaser .nm-homepage-content-news-wide-headline {
	font-weight: bold;
}

html.nm-scope-far-east .nm-el-hdl-02 {
	font-size: 2.3rem;
	line-height: 2.8rem;
}

html.nm-scope-far-east .nm-el-hdl-04 {
	font-size: 1.4rem;
	line-height: 2.2rem;
}

html.nm-scope-far-east .nm-el-pg-03 {
	font-size: 1.4rem;
}

html.nm-scope-far-east .nm-el-pg-04 {
	font-size: 1.4rem;
	line-height: 2rem;
}

html.nm-scope-far-east .nm-el-lk-01 {
	font-size: 1.4rem;
	line-height: 2rem;
}

html.nm-scope-far-east .nm-el-btn-01 {
	font-size: 1.4rem;
	line-height: 2rem;
}

html.nm-scope-far-east .nm-el-lk.nm-el-lk-ast::before {
	top: 3px;
}

.nm-form-standard-error::before,
.nm-error::before {
	top: 2px;
}

html.nm-scope-far-east .nm-md-highlight .nm-el-hdl-02 {
	font-size: 3.2rem;
	line-height: 4rem;
}

html.nm-scope-far-east .nm-md-highlight .nm-el-hdl-04 {
	font-size: 1.4rem;
	line-height: 2.2rem;
}

/* intro  modul */
html.nm-scope-far-east .nm-md-intro .nm-el-pg-03 {
	font-size: 1.6rem;
	line-height: 2.4rem;
}

html.nm-scope-far-east .nm-md-intro .nm-el-lk-01 {
	font-size: 1.2rem;
	line-height: 1.8rem;
}

@media all and (max-width: 749px) {
	html.nm-scope-far-east.nm-state-is-responsive .nm-el-hdl-02 {
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
}
