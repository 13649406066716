/* internationalisation */
.nm-text-is-missing {
	background: #32cd32;
	color: #333;
	display: inline;
	font-style: oblique;
}

/* Module-Modus */
.nm-state-is-module [data-module] {
	outline: 1px dotted rgb(0, 0, 255);
}

.nm-state-is-module [data-module]::before {
	background: rgb(0, 0, 255);
	color: rgb(255, 255, 255);
	content: "Module: " attr(data-module);
	display: block;
	font-weight: bold;
	padding: 5px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
}

/* SC Module is-module modus */
.nm-state-is-module .sc-module {
	position: relative;
}

/* Render-Modus */
.nm-align-center {
	text-align: center;
}

.nm-align-right {
	text-align: right;
}
